@import "assets/Colors/index.scss";

.headerContent{
  background-color: $secondaryLight !important;
}

.navlink{
  color: $white !important;
  text-decoration: none;
}

.navlinkActive{
  color: $white !important;
  text-decoration: underline;
}

.navContainer{
  gap: 10px;
  align-items: baseline;
}
.card{
  margin: 4rem 0 !important
}

.cardHeader{
  background-color: #FFFFFF !important;
  border-bottom: none !important;
  text-align: center;
}

.buttonSearch{
  align-self: flex-end !important;
  text-align: right;
}

.inputForm{
  margin: 0.5rem 0 !important;
}

.buttonsPagination{
  display: flex;
  justify-content: space-around;
}

.infoTable {
  white-space: nowrap;
  text-align: center;
}
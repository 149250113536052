@import "../../assets/Colors/index.scss";

.Card {
  margin-top: 2.5rem;
  border-radius: 10px;
  height: 100%;
  padding-top: 5px;
  width: 100%;
}

.Header{
  background-color: #fff;
  border-bottom: none;
  height: 100px;
}

.Title{
  display: flex;
  justify-content: center;
}

.Switch{
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}

.Body{
  display: grid;
  grid-template-rows: auto auto auto auto auto auto auto;
  grid-template-columns: 100%;
  padding: 0;
}

.Day{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 30px;
}

.day_title{
  position: relative;
  top: 5px;
  text-align: center;
  font-size: 15px;
  margin: 0;
  height: 45px;
  color: $text;
}

.day_content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: scroll;
}

.day_content div{
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.day_content::-webkit-scrollbar{
  height: 10px;
}

.day_content::-webkit-scrollbar-thumb {
  background: $secondary; 
  border-radius: 15px;

}
